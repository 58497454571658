<template>
  <div id="lay_content_all1">
    <lay-header v-if="![ '/zhPdfView'].includes($route.path)"></lay-header>
    <div class="heightzw" v-if="![ '/zhPdfView'].includes($route.path)"><!--高度占位--></div>
    <div class="lay_content_all"
         :style="{height:listarr.includes($route.path)?'calc(100vh - 5rem)':'calc(100vh - 9.6rem)'}">

      <keep-alive :include="['zhBookList','inforindex_mnName','inforindex_zhName']">
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    LayHeader: re => require(['./LayHeader_zh.vue'], re),
  },

  data() {
    return {
      listarr: ['/zhBookInfo','/index_cn','/index_infor','/h5Iframe','/zhPdfView','/index_infor_mn'],
      onFooterH: 0,
    }
  },
  methods: {},
  computed: {},
  mounted() {

  },


}
</script>
<style lang="less">
#lay_content_all1 {
  width: 100%;

  .heightzw {
    width: 100%;
    height: 5rem;
  }

  .lay_content_all {
    width: 100%;
  }

  .saomaxx {
    width: 100%;
    height: 50px;
    z-index: 9999;
    background: var(--themeColor);
    position: absolute;
    bottom: 0;
    display: flex;
    color: #fff;
    font-size: 16px;

    i {
      font-size: 23px;
    }

    .left {
      height: 50px;
      line-height: 50px;
      text-align: center;
      width: 50%;
      border-right: 1px solid #fff;
    }

    .right {
      text-align: center;
      width: 50%;
      line-height: 50px;
      height: 50px;
    }
  }
}
</style>

